import React from "react";

import Header from "../partials/Header";
import HeroHome from "../partials/HeroHome";
import FeaturesHome from "../partials/Features";
import FeaturesBlocks from "../partials/FeaturesBlocks";
import Testimonials from "../partials/Testimonials";
import Footer from "../partials/Footer";
import Layout from "../components/layout/layout";
import SEO from "../components/seo/seo";

function Home() {
  return (
    <Layout>
      <SEO siteTitle="WP Admin Dashboard Theme" />
      <div className="font-inter antialiased bg-white text-gray-900 tracking-tight">
        <div className="flex flex-col min-h-screen overflow-hidden">
          <Header />
          {/*  Page content */}
          <main className="flex-grow">
            <HeroHome />
            <FeaturesHome />
            <FeaturesBlocks />
            <Testimonials />
          </main>
          <Footer />
        </div>
      </div>
    </Layout>
  );
}

export default Home;
